import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'

const Testing = props => {
  return (
    <div className='container col-12'>
      <div className='row'>
        <div className='col-12 col-md-3 ' style={{ cursor: 'pointer' }}>
          <div className='row'>
            <div className='flex-md-row '>
              <ul class='list-group flex-sm-column flex-row'>
                <li class='list-group-item'>My Account</li>
                <li class='list-group-item'>My Bookings</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testing
